import { createStore } from "vuex";

export default createStore({
  state: {
    SheetId: "1i5CRr6JZkCSaMP0Uw-kyhTNgQlWPvGOsPItoFBOzEN4",
    Key: "AIzaSyCB4RyhZPd1GNWHBMZUSZAMltVw1PrlRVs",
    menu: [
      {
        label: "Dashboard",
        icon: "r_dashboard",
        route: { name: "Dashboard" },
      },
      {
        label: "Expense Entry",
        icon: "r_receipt_long",
        route: { name: "DataEntry", query: { title: "Expense Entry" } },
      },
      {
        label: "Income Entry",
        icon: "r_receipt_long",
        route: {
          name: "DataEntry",
          query: { type: "income", title: "Income Entry" },
        },
      },
      // {
      //   label: "Income Entry",
      //   icon: "r_receipt_long",
      //   route: { name: "Input" },
      // },
      //{ label: "Data Entry", icon: "r_receipt_long", route: { name: "Input" } },
      //{ label: "Transactions", icon: "r_payments", route: { name: "Summary" } },
      //{ label: "Configure", icon: "r_payments", route: { name: "Configure" } },
      { label: "About", icon: "r_info", route: { name: "About" } },
    ],
    headerTitle: null,
    signedIn: false,
  },
  getters: {
    SheetId(state) {
      return state.SheetId;
    },
    Key(state) {
      return state.Key;
    },
    Menu(state) {
      return state.menu;
    },
    HeaderTitle(state) {
      console.warn("UpdateHeader", state.headerTitle);
      return state.headerTitle;
    },
    SignedIn(state) {
      return state.signedIn;
    },
  },
  mutations: {
    updateHeaderTitle(state, payload) {
      state.headerTitle = payload;
    },
    updateSignInStatus(state, payload) {
      state.signedIn = payload;
    },
  },
  actions: {
    UpdateHeader({ commit }, payload) {
      commit("updateHeaderTitle", payload);
    },
    UpdateSignInStatus({ commit }, payload) {
      commit("updateSignInStatus", payload);
    },
  },
  modules: {},
});
