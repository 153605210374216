import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";

import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Cookies from 'quasar/src/plugins/Cookies.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog,
    Cookies,
    LocalStorage,
  },
};
