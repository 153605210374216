import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../pages/Dashboard.vue"),
      },
      {
        path: "entry",
        name: "Input",
        component: () => import("../pages/Input.vue"),
      },
      {
        path: "expense",
        name: "DataEntry",
        component: () => import("../pages/DataEntry.vue"),
      },
      {
        path: "summary",
        name: "Summary",
        component: () => import("../pages/Summary.vue"),
      },
      {
        path: "",
        name: "Unauthorized",
        component: () => import("../pages/Unauthorized.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/Unauthorized.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
